.nBFwQOE2bozqR47AgOA-I {
  margin-top: 10px;
  padding: 20px 0 20px 20px;
  background-color: #fff; }
  .nBFwQOE2bozqR47AgOA-I ._2FareRU_GmJ5EVrwpRykbz {
    margin: 0 15px; }
  .nBFwQOE2bozqR47AgOA-I ._2OP02oRP8IDGqwPr-IIYeG {
    border-right: 1px dashed #e8e8e8; }
    .nBFwQOE2bozqR47AgOA-I ._2OP02oRP8IDGqwPr-IIYeG .nE6hW8-MS0EkH94rq0lTl {
      border: 1px solid #d8d8d8;
      width: 133px;
      height: 133px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .nBFwQOE2bozqR47AgOA-I ._2OP02oRP8IDGqwPr-IIYeG .nE6hW8-MS0EkH94rq0lTl img {
        max-width: 131px; }
    .nBFwQOE2bozqR47AgOA-I ._2OP02oRP8IDGqwPr-IIYeG ._28DzDZ5zPVxDd_jh07UcxS {
      width: calc(100% - 133px); }
      .nBFwQOE2bozqR47AgOA-I ._2OP02oRP8IDGqwPr-IIYeG ._28DzDZ5zPVxDd_jh07UcxS .j34UVkHFMxer7pReL5crY {
        color: #000000;
        font-weight: bold;
        text-decoration: none;
        cursor: pointer;
        line-height: 22px;
        font-size: 0.875rem;
        margin-bottom: 10px; }
      .nBFwQOE2bozqR47AgOA-I ._2OP02oRP8IDGqwPr-IIYeG ._28DzDZ5zPVxDd_jh07UcxS ._2yfvcBw8kpBKzItajCrI76 {
        font-size: 0.7rem;
        line-height: 16px; }
  .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk {
    display: flex;
    justify-content: flex-end; }
    .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk ._2tmqFsa1Li3jhDAaw7JrlP {
      display: flex; }
      .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk ._2tmqFsa1Li3jhDAaw7JrlP ._2NqG_nYQIQEHuPEM8_D8zX {
        color: #d0021b; }
      .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk ._2tmqFsa1Li3jhDAaw7JrlP .gTKwS5OKHciix71xAS0o8 {
        margin-left: 10px; }
        .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk ._2tmqFsa1Li3jhDAaw7JrlP .gTKwS5OKHciix71xAS0o8 div {
          font-weight: bold; }
        .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk ._2tmqFsa1Li3jhDAaw7JrlP .gTKwS5OKHciix71xAS0o8 ._37iduw92uWVF2akfg6opw1 {
          color: #417505; }
        .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk ._2tmqFsa1Li3jhDAaw7JrlP .gTKwS5OKHciix71xAS0o8 ._1KK2ZhWRD1XG1ujVdik5LX {
          font-size: 0.75rem; }
    .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk .n208uNSJgl8BvdNw43Ks8 {
      padding-right: 0px; }
      .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk .n208uNSJgl8BvdNw43Ks8 .akAyCpQPbHovypyx6LHZo {
        display: flex; }
        .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk .n208uNSJgl8BvdNw43Ks8 .akAyCpQPbHovypyx6LHZo .WU7Lpm-IVUxHLrneTJlMP {
          display: flex;
          flex-direction: column;
          align-items: flex-end; }
          .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk .n208uNSJgl8BvdNw43Ks8 .akAyCpQPbHovypyx6LHZo .WU7Lpm-IVUxHLrneTJlMP ._1zXHsinYkz8b1r_9hbGT4W {
            margin-top: 10px;
            color: #000000;
            font-size: 0.75rem;
            font-weight: 300; }
        .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk .n208uNSJgl8BvdNw43Ks8 .akAyCpQPbHovypyx6LHZo ._9juqvLbXc5XivxONRj54i {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          color: #000000;
          font-weight: bold; }
      .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk .n208uNSJgl8BvdNw43Ks8 .Uh6T2JG4AbL9xgooWBZZz {
        display: block;
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none; }
      .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk .n208uNSJgl8BvdNw43Ks8 .Uh6T2JG4AbL9xgooWBZZz input {
        display: none; }
      .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk .n208uNSJgl8BvdNw43Ks8 ._2P4L8qKoK2cM2Xw7algJL1 {
        position: absolute;
        top: 0;
        right: 0;
        height: 21px;
        width: 21px;
        background-color: #eee;
        border: 1px solid #979797; }
      .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk .n208uNSJgl8BvdNw43Ks8 .Uh6T2JG4AbL9xgooWBZZz:hover input ~ ._2P4L8qKoK2cM2Xw7algJL1 {
        background-color: #ccc; }
      .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk .n208uNSJgl8BvdNw43Ks8 .Uh6T2JG4AbL9xgooWBZZz input:checked ~ ._2P4L8qKoK2cM2Xw7algJL1 {
        background-color: #01a3e5; }
      .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk .n208uNSJgl8BvdNw43Ks8 ._2P4L8qKoK2cM2Xw7algJL1:after {
        content: '';
        position: absolute;
        display: none; }
      .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk .n208uNSJgl8BvdNw43Ks8 .Uh6T2JG4AbL9xgooWBZZz input:checked ~ ._2P4L8qKoK2cM2Xw7algJL1:after {
        display: block; }
      .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk .n208uNSJgl8BvdNw43Ks8 .Uh6T2JG4AbL9xgooWBZZz ._2P4L8qKoK2cM2Xw7algJL1:after {
        right: 6px;
        top: 2px;
        width: 8px;
        height: 13px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg); }
      .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk .n208uNSJgl8BvdNw43Ks8 ._2BFKbdWfOfjtug7WQRftyR {
        margin-top: 30px;
        display: flex;
        justify-content: flex-end; }
        .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk .n208uNSJgl8BvdNw43Ks8 ._2BFKbdWfOfjtug7WQRftyR button {
          border: none;
          box-shadow: none;
          cursor: pointer;
          background: transparent; }
          .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk .n208uNSJgl8BvdNw43Ks8 ._2BFKbdWfOfjtug7WQRftyR button i {
            font-size: 1rem; }
          .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk .n208uNSJgl8BvdNw43Ks8 ._2BFKbdWfOfjtug7WQRftyR button:focus {
            outline: 0; }

@media (max-width: 767px) {
  .nBFwQOE2bozqR47AgOA-I ._2OP02oRP8IDGqwPr-IIYeG {
    border-right: none;
    border-bottom: 1px dashed #e8e8e8;
    margin-bottom: 20px;
    padding-bottom: 20px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .nBFwQOE2bozqR47AgOA-I ._2OP02oRP8IDGqwPr-IIYeG ._28DzDZ5zPVxDd_jh07UcxS {
      width: 100%;
      padding: 0;
      margin-top: 10px; }
  .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0; }
    .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk .n208uNSJgl8BvdNw43Ks8 {
      padding: 0;
      margin-top: 10px; }
      .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk .n208uNSJgl8BvdNw43Ks8 .akAyCpQPbHovypyx6LHZo {
        display: flex;
        align-items: center; }
        .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk .n208uNSJgl8BvdNw43Ks8 .akAyCpQPbHovypyx6LHZo .WU7Lpm-IVUxHLrneTJlMP {
          display: flex;
          flex-direction: column;
          align-items: center; }
          .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk .n208uNSJgl8BvdNw43Ks8 .akAyCpQPbHovypyx6LHZo .WU7Lpm-IVUxHLrneTJlMP ._1zXHsinYkz8b1r_9hbGT4W {
            margin-top: 10px;
            color: #000000;
            font-size: 0.75rem;
            font-weight: bold; }
        .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk .n208uNSJgl8BvdNw43Ks8 .akAyCpQPbHovypyx6LHZo ._9juqvLbXc5XivxONRj54i {
          width: 100%;
          display: flex;
          margin-right: 20px;
          justify-content: flex-end;
          color: #000000;
          font-weight: bold; }
      .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk .n208uNSJgl8BvdNw43Ks8 ._2BFKbdWfOfjtug7WQRftyR {
        margin-top: 30px;
        display: flex;
        justify-content: center; }
        .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk .n208uNSJgl8BvdNw43Ks8 ._2BFKbdWfOfjtug7WQRftyR button {
          border: none;
          box-shadow: none;
          cursor: pointer;
          background: transparent; }
          .nBFwQOE2bozqR47AgOA-I ._2XsRLWnJW1OGIB0gfG4FMk .n208uNSJgl8BvdNw43Ks8 ._2BFKbdWfOfjtug7WQRftyR button:focus {
            outline: 0; } }
