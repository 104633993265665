._1oRzb8x-zbvUkDbGT2uTzo {
  border: 1px solid lightgray;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100px;
  height: 100px;
  cursor: pointer; }
  ._1oRzb8x-zbvUkDbGT2uTzo:first-of-type {
    margin-top: 0px; }
  ._1oRzb8x-zbvUkDbGT2uTzo:last-of-type {
    margin-bottom: 0px; }

._3bZeAVoW2CG8JxflFcTPO {
  margin: 0 5px; }

._1ddX3iPtNbepyklGh1-o91 {
  background-size: contain;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center; }

._1oRzb8x-zbvUkDbGT2uTzo img {
  align-self: center; }

._4mCGmK9S6Wc6i8H5tF6xu {
  opacity: 0.6;
  transition: 0.5s opacity; }

._4mCGmK9S6Wc6i8H5tF6xu:hover {
  opacity: 1; }

._10HedID-uEGgALVhPHBUkl {
  object-fit: cover; }
  @media (max-width: 991px) {
    ._10HedID-uEGgALVhPHBUkl {
      max-height: 200px; } }
  @media (min-width: 991px) {
    ._10HedID-uEGgALVhPHBUkl {
      max-width: 100%;
      max-height: 30em; } }

._3ktBRK5wJ9gJ2Chl-GoxNI {
  display: flex; }

._12-0C28k0juQZVQ71qyv5A {
  width: 100%;
  display: flex; }

.RV7mnImxYSg_qsxH6jUoG {
  width: 90%;
  height: 510px;
  display: flex;
  flex-direction: column-reverse; }

.apAnFRv7c2gYBC2eZjhs4 i {
  align-self: center;
  width: 100%; }

.apAnFRv7c2gYBC2eZjhs4 > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px; }

.ajj4NGWCcUkulWJwbeeHv > div {
  padding-top: 20px;
  flex-direction: row; }

._3EMqL_nESV1vU9zkdZRdjc {
  flex: 4;
  align-content: center;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  display: flex; }
  @media (max-width: 768px) {
    ._3EMqL_nESV1vU9zkdZRdjc {
      align-items: center; } }
  ._3EMqL_nESV1vU9zkdZRdjc ._2Ef_q3P0UEnvYXpI1tBaC4 {
    margin: 0; }
    @media (max-width: 991px) {
      ._3EMqL_nESV1vU9zkdZRdjc ._2Ef_q3P0UEnvYXpI1tBaC4 {
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center; } }
  @media (max-width: 991px) {
    ._3EMqL_nESV1vU9zkdZRdjc ._2Ef_q3P0UEnvYXpI1tBaC4._3isGm5VU4pm0vWUL6qnIv_ {
      height: 350px; } }
  @media (max-width: 991px) {
    ._3EMqL_nESV1vU9zkdZRdjc ._1f2TqhfJU7lnTVHF0LfC2V {
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      padding: 0 4px; } }

._1g3tDXYreTRhZqUOxuSV7A {
  justify-content: flex-start !important;
  flex: auto !important; }
  ._1g3tDXYreTRhZqUOxuSV7A ._10HedID-uEGgALVhPHBUkl {
    max-width: 100%; }

._3RYh2Hoj_7QrccQQG91Wj2:hover {
  color: gray; }

._3RYh2Hoj_7QrccQQG91Wj2 {
  font-size: 1.875rem;
  color: darkgray; }

._2P8nhdnpNTU1FKHdoi3VfB {
  cursor: pointer; }

@media (max-width: 991px) {
  ._2clNYjC4wo-aqRLfoBfmZL {
    margin-left: 0px !important; } }

@media (max-width: 991px) {
  ._1VseGjUpU4qRS8eMNGGGeO {
    max-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center; } }

._9-W7mgpAhwFloQG9Yr3vf:before {
  content: '\F144';
  font-size: 1.25rem;
  text-align: center;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  line-height: 100px; }

.hoOSduwt989mDXLE9TzjD {
  opacity: 1;
  transition: opacity 0.5s linear; }

@keyframes _2yPimEI0zqqfhfCqe7UQ97 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.dKmEdr_8nRFr_RD6v2hNA {
  display: none; }

._3KySJItAJHux9SiOOaA4qb {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 35px; }

._3w3PcKatAMTRO4vLNnFJ4v {
  position: relative;
  height: 100%; }
