._2vb0XSHPVsbCgyUTo28LgL {
  display: flex;
  align-items: center;
  justify-content: center; }

._1KiERxpukn50YpcTiJ070T {
  margin-left: 5px; }

._1LELYt4JGp5KOeRBHXWEi8 {
  display: inline-block; }

._27wVle2zsAibpEGdPo1xxU {
  border: 1px solid #eee; }

._2mSoAyqSmbPJKW77s_5Qmw {
  display: flex;
  align-items: center; }
