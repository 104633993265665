._2fVmSQry9fQKGjeGAVtv_z {
  position: relative;
  display: inline-block; }

@media (max-width: 768px) {
  ._2fVmSQry9fQKGjeGAVtv_z {
    margin-left: 10px; } }

._1K7WA0Fli5Ws_LHMNeCclN {
  display: flex;
  height: 42px;
  width: 62px;
  justify-content: space-between;
  position: relative;
  border: 1px solid #BBBBBB; }

._2ciSoCIGKs5B0MnAgHwabn {
  align-self: center;
  cursor: pointer;
  margin-left: 7px;
  user-select: none;
  color: #9B9B9B; }

._3gaHH6X8ONth65G47wYmtf {
  color: #9B9B9B;
  align-self: center; }

._1QGYkFiS-a1AcBdXWWrh0C {
  align-self: center;
  margin-right: 7px;
  cursor: pointer;
  user-select: none;
  color: #9B9B9B; }
