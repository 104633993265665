._1GwyDayprZnbD5GhGECElb {
  margin-top: 10px;
  padding: 20px 10px;
  background-color: #fff; }
  ._1GwyDayprZnbD5GhGECElb ._3yQkWxhpQVfabMBmvfG3SJ {
    display: flex;
    justify-content: space-between; }
    ._1GwyDayprZnbD5GhGECElb ._3yQkWxhpQVfabMBmvfG3SJ ._1abFjd1pb9rYwkMypVBF6s {
      font-weight: bold;
      margin-bottom: 10px; }
    ._1GwyDayprZnbD5GhGECElb ._3yQkWxhpQVfabMBmvfG3SJ .O4qvgvXwhHk8HAixTqNDs {
      font-weight: bold;
      margin-bottom: 10px; }
    ._1GwyDayprZnbD5GhGECElb ._3yQkWxhpQVfabMBmvfG3SJ .nqBomZTAZ3OmA50Z5387d {
      color: #ce0b24; }

._1xh1_VWB2T5DcDmQsQRfdV {
  height: 50px;
  flex: 1;
  display: flex; }
  ._1xh1_VWB2T5DcDmQsQRfdV ._2I7b_yMem1o3RUxml2wGHt {
    margin: 0;
    border: 1px solid #bbb;
    border-right: none;
    height: 100%;
    flex: 1;
    padding: 0px 10px;
    font-weight: bold;
    font-size: 1rem;
    outline: none !important; }
    @media only screen and (max-width: 375px) {
      ._1xh1_VWB2T5DcDmQsQRfdV ._2I7b_yMem1o3RUxml2wGHt {
        width: 50%; } }
    ._1xh1_VWB2T5DcDmQsQRfdV ._2I7b_yMem1o3RUxml2wGHt::placeholder {
      color: #777; }
  ._1xh1_VWB2T5DcDmQsQRfdV ._1hLtLIGbTU_6f7n-nNRQh0 {
    margin: 0;
    border: none;
    height: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 1.2rem;
    border: 1px solid #ccc;
    cursor: pointer; }

._4hKZyEtkJFOZvIf24uSxI {
  display: block;
  width: 100%;
  text-align: center;
  color: red; }

.h96d3qpvZ7rfS9CgePVpD {
  color: #068b3e; }
