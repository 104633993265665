._3X7eHzFuqLLbHFg2g-gQ6r {
  height: 24px;
  width: 24px; }
  ._3X7eHzFuqLLbHFg2g-gQ6r ._3Z_TiyGjR87wE9wl0BORU7 {
    fill: '#009444'; }
  ._3X7eHzFuqLLbHFg2g-gQ6r ._1X6kQKYaJ95ictsjyJVxN {
    fill: #1c75bc; }
  ._3X7eHzFuqLLbHFg2g-gQ6r ._33Uj0iRK51U-LQRHalXOle {
    fill: #005baa; }
  ._3X7eHzFuqLLbHFg2g-gQ6r .Z8KykqUDPjs_AvAzsVcLI {
    fill: #21409a; }
  ._3X7eHzFuqLLbHFg2g-gQ6r ._1x7E18f44Rh0mSw65r5-Ty {
    fill: #583f99; }
  ._3X7eHzFuqLLbHFg2g-gQ6r .WCAQrHVKWXE8vW0GUoOj- {
    fill: #92278f; }
  ._3X7eHzFuqLLbHFg2g-gQ6r .JJpEK3cvyWSM0xpQZenxD {
    fill: #ed1c24; }
  ._3X7eHzFuqLLbHFg2g-gQ6r .UXO1QtlCnerAiNUs08LWb {
    fill: #f26522; }
  ._3X7eHzFuqLLbHFg2g-gQ6r ._1tXHySmnAPye8xS2oSJ6s3 {
    fill: #f7941d; }
  ._3X7eHzFuqLLbHFg2g-gQ6r ._16JCpo8SViMGm2i-rb15UM {
    fill: #fbb040; }
  ._3X7eHzFuqLLbHFg2g-gQ6r ._2qa9z0LdAff0V7DaNHrg2S {
    fill: #fff200; }
  ._3X7eHzFuqLLbHFg2g-gQ6r ._27x1nM_IwwOW2wsMafnm_a {
    fill: #8dc63f; }
