.fyUKkFZigCvOmjdvx9-2a {
  text-transform: uppercase; }

._7dVaN5P0zt2XVbQ3X7GHM {
  cursor: pointer;
  color: #4A90E2;
  display: inline-block; }
  ._7dVaN5P0zt2XVbQ3X7GHM:hover {
    text-decoration: underline; }

._2pOZtbpqNSepY4YfNRfMIJ {
  background-color: #F7F7F7;
  padding: 15px; }
  ._2pOZtbpqNSepY4YfNRfMIJ .u7lyU31YkkU6i-86BRSnz {
    display: flex;
    align-items: center; }
    ._2pOZtbpqNSepY4YfNRfMIJ .u7lyU31YkkU6i-86BRSnz ._11PSZq5EgeKv1Pornl7CNX {
      color: #000000;
      font-size: 1.75rem;
      font-weight: 300;
      line-height: 2.25rem; }
    ._2pOZtbpqNSepY4YfNRfMIJ .u7lyU31YkkU6i-86BRSnz ._1Sl0eRb9kgxRoe8ofwzbfL {
      display: flex;
      flex-grow: 1;
      flex-direction: row; }
      ._2pOZtbpqNSepY4YfNRfMIJ .u7lyU31YkkU6i-86BRSnz ._1Sl0eRb9kgxRoe8ofwzbfL ._1e5nG69Npau_UzjlmnzvN6 {
        margin: 0 0 0 auto;
        padding-left: 10px; }
        ._2pOZtbpqNSepY4YfNRfMIJ .u7lyU31YkkU6i-86BRSnz ._1Sl0eRb9kgxRoe8ofwzbfL ._1e5nG69Npau_UzjlmnzvN6 ._1F84_gpPg73pv_e0fycSUH {
          margin-right: 5px; }
    ._2pOZtbpqNSepY4YfNRfMIJ .u7lyU31YkkU6i-86BRSnz ._1w1yljB11jWfgzMCt45eTP {
      display: none; }
    ._2pOZtbpqNSepY4YfNRfMIJ .u7lyU31YkkU6i-86BRSnz ._3jDO4w78HQI_6rTHOOiLkl {
      margin-left: 30px; }

@media (max-width: 767px) {
  ._2pOZtbpqNSepY4YfNRfMIJ .u7lyU31YkkU6i-86BRSnz {
    display: flex;
    flex-direction: column; }
    ._2pOZtbpqNSepY4YfNRfMIJ .u7lyU31YkkU6i-86BRSnz ._3jDO4w78HQI_6rTHOOiLkl {
      margin-left: 0; }
    ._2pOZtbpqNSepY4YfNRfMIJ .u7lyU31YkkU6i-86BRSnz ._1Sl0eRb9kgxRoe8ofwzbfL {
      width: 100%;
      flex-direction: row; }
      ._2pOZtbpqNSepY4YfNRfMIJ .u7lyU31YkkU6i-86BRSnz ._1Sl0eRb9kgxRoe8ofwzbfL ._1e5nG69Npau_UzjlmnzvN6 {
        flex: 1;
        text-align: right;
        margin: 0; }
      ._2pOZtbpqNSepY4YfNRfMIJ .u7lyU31YkkU6i-86BRSnz ._1Sl0eRb9kgxRoe8ofwzbfL .k5l1j8bgZDz0V1jyo4eUR {
        display: none; }
      ._2pOZtbpqNSepY4YfNRfMIJ .u7lyU31YkkU6i-86BRSnz ._1Sl0eRb9kgxRoe8ofwzbfL ._3jDO4w78HQI_6rTHOOiLkl {
        display: block;
        text-align: left; }
    ._2pOZtbpqNSepY4YfNRfMIJ .u7lyU31YkkU6i-86BRSnz ._1w1yljB11jWfgzMCt45eTP {
      width: 100%;
      display: flex; }
      ._2pOZtbpqNSepY4YfNRfMIJ .u7lyU31YkkU6i-86BRSnz ._1w1yljB11jWfgzMCt45eTP ._1F84_gpPg73pv_e0fycSUH {
        margin-right: 5px; }
      ._2pOZtbpqNSepY4YfNRfMIJ .u7lyU31YkkU6i-86BRSnz ._1w1yljB11jWfgzMCt45eTP .k5l1j8bgZDz0V1jyo4eUR {
        flex: 1;
        text-align: right; } }
