._1DQnuDvmuUgiDYlYS1Giiz {
  margin-bottom: 30px; }

._1Nfdb2hMDsuM_NOiZ_ArA3 {
  margin-bottom: 20px; }

._3rZbHEz6E5Aw9B1Rq6h477 {
  text-align: right; }
  @media (max-width: 768px) {
    ._3rZbHEz6E5Aw9B1Rq6h477 {
      text-align: left; } }
