.jIOnt-Uxqwk_yQO0HuR6j:hover {
  color: gray; }

.jIOnt-Uxqwk_yQO0HuR6j {
  font-size: 1.875rem;
  color: darkgray;
  width: 21px; }

@media (max-width: 991px) {
  ._7WLomnkBISNIzZF_O3HBk {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    padding: 0 4px; } }
