._2A36RhYId_NFhHOrJb-u17 {
  display: block;
  max-width: 100%;
  max-height: 100%; }

._1xog0N4QGDrO4IsQ-ixgpC {
  border: 1px solid #eee; }

._2kdgDAX69CoHMlKR_WHGar {
  margin: 0 auto; }
