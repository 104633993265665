._33Yj6zYoqPfUSjYjJCZxnL {
  margin-right: 15px; }

.Z_CI1Tc8hGExurH24h0IX {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  cursor: progress;
  z-index: 9999; }

._2tMmJ1AoJ9kkCRekUPU3lW {
  display: flex;
  align-items: end; }

._1ymfjDfyxjJj6XTwPbcMpy {
  display: flex;
  margin-top: 20px;
  cursor: pointer; }
  ._1ymfjDfyxjJj6XTwPbcMpy .hofvJLZbIELskDr3OZuCV {
    align-self: end;
    margin-left: 5px;
    text-decoration: underline;
    font-weight: bold;
    color: #007ea8; }
  ._1ymfjDfyxjJj6XTwPbcMpy .hofvJLZbIELskDr3OZuCV._3jCvGZ0ksOPkoD77UpSiBe {
    color: #ccc; }

._1ymfjDfyxjJj6XTwPbcMpy._3jCvGZ0ksOPkoD77UpSiBe {
  pointer-events: none; }

._33jMu-UKBXdHHhOUlyBaYg {
  font-size: 14px;
  margin-left: 9px;
  margin-top: 4px; }

._2l1SqiyacVJW4xzk1c2oiC {
  margin-top: 20px; }
  ._2l1SqiyacVJW4xzk1c2oiC label {
    line-height: 1.5; }

._12E8FSSm02Om1HeoCU5BPT {
  display: none; }

._2Ct4sxO0MTfQIe6zrt8okj {
  display: block; }

@media (min-width: 768px) {
  ._12E8FSSm02Om1HeoCU5BPT {
    display: block; }
  ._2Ct4sxO0MTfQIe6zrt8okj {
    display: none; } }

.RZCmlspgqduyrh9_9_pTI {
  position: fixed;
  bottom: 0;
  height: 80px;
  z-index: 4998;
  width: 100%;
  background-color: white;
  left: 0; }

@media (min-width: 768px) {
  .RZCmlspgqduyrh9_9_pTI {
    display: none; } }

.P7nflK7vLiQX8GudHhGyy {
  margin-right: 15px;
  margin-top: 15px; }

.UmyQ2KW4xiL4XmZJItgeb {
  margin-right: 40px;
  margin-top: 15px;
  margin-left: 40px; }

._zhUXTsxmraBwQhN74T2P {
  display: flex !important;
  margin: 5px 0px;
  justify-content: space-between;
  align-items: baseline; }
