.HsJBmdKiHhNktwpyjqQnC {
  align-items: flex-start;
  background: transparent;
  border-radius: 3px;
  border: 1px solid #1a2638;
  box-shadow: inset 0 0 1px 0 #151500;
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  justify-content: flex-start;
  max-height: 200px;
  opacity: 1;
  overflow-y: auto;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-top: 5px;
  white-space: nowrap;
  margin-right: 12px; }

._3awM46X9FF9Pfl0dWq25CN {
  width: 93px; }

.HsJBmdKiHhNktwpyjqQnC._367gpHENrQB9cubAqQZDOr {
  pointer-events: none;
  opacity: 0.5; }

@media (max-width: 768px) {
  .HsJBmdKiHhNktwpyjqQnC {
    flex-wrap: nowrap; } }

._2yoEjIHG59xsYUjKZgH5KL {
  font-size: 12px;
  margin-bottom: 20px;
  margin-top: 5px;
  text-align: center;
  white-space: pre-wrap;
  width: 80px; }

._2PN-EhfkMFGcbwO3KlFhAY {
  cursor: pointer;
  display: inline-block;
  margin-right: 5px;
  position: relative; }
  ._2PN-EhfkMFGcbwO3KlFhAY:hover {
    filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.5));
    -webkit-filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.5)); }
  ._2PN-EhfkMFGcbwO3KlFhAY._17-yepRRzH9atn1enWfRiY {
    filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.5));
    -webkit-filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.5)); }
  ._2PN-EhfkMFGcbwO3KlFhAY :first-child {
    height: 80px;
    width: 80px; }
