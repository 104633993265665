._3MpsywhqWAxnvrJOI5cXl0 {
  color: red;
  text-align: right;
  margin-top: -15px; }

._3ifROftlLo12_qx7WxmKQk {
  background-color: #F7F7F7;
  padding: 15px;
  line-height: 1.5; }
  ._3ifROftlLo12_qx7WxmKQk .VIGpPZYPQ9QtLwbH9fqIX {
    font-weight: bold;
    margin-bottom: 10px; }
  ._3ifROftlLo12_qx7WxmKQk ._3unHp8EIW_xEJz_srw1S_E {
    margin-top: 10px;
    padding: 20px 10px;
    background-color: #fff; }
    ._3ifROftlLo12_qx7WxmKQk ._3unHp8EIW_xEJz_srw1S_E ._2Ds5cJ27ouZW4TTglbK8On {
      display: flex;
      justify-content: space-between; }
      ._3ifROftlLo12_qx7WxmKQk ._3unHp8EIW_xEJz_srw1S_E ._2Ds5cJ27ouZW4TTglbK8On ._1r_ZoG6MlkVBCdpeReJGbl {
        font-weight: bold;
        margin-bottom: 10px; }
      ._3ifROftlLo12_qx7WxmKQk ._3unHp8EIW_xEJz_srw1S_E ._2Ds5cJ27ouZW4TTglbK8On ._1bIpEEAY_ddMTdVWY3HaX1 {
        font-weight: bold;
        margin-bottom: 10px; }
      ._3ifROftlLo12_qx7WxmKQk ._3unHp8EIW_xEJz_srw1S_E ._2Ds5cJ27ouZW4TTglbK8On ._1rU-gJfjYNI6Y9TybJvJdE {
        color: #ce0b24; }
  ._3ifROftlLo12_qx7WxmKQk ._3xdfF4AMOUY-hi2rFRdU7m {
    height: 50px;
    flex: 1;
    display: flex; }
    ._3ifROftlLo12_qx7WxmKQk ._3xdfF4AMOUY-hi2rFRdU7m ._109mHz3VUMS_4GoOQ01kiS {
      margin: 0;
      border: 1px solid #bbb;
      border-right: none;
      height: 100%;
      flex: 1;
      padding: 0px 10px;
      font-weight: bold;
      font-size: 1rem;
      outline: none !important; }
      ._3ifROftlLo12_qx7WxmKQk ._3xdfF4AMOUY-hi2rFRdU7m ._109mHz3VUMS_4GoOQ01kiS::placeholder {
        color: #777; }
    ._3ifROftlLo12_qx7WxmKQk ._3xdfF4AMOUY-hi2rFRdU7m ._2cb-D5P9rthCLG0SWvRrsl {
      margin: 0;
      border: none;
      height: 100%;
      padding: 0 10px;
      box-sizing: border-box;
      font-weight: bold;
      font-size: 1.2rem;
      cursor: pointer; }
  ._3ifROftlLo12_qx7WxmKQk ._2fFYChvdK-uDWrFnwLBtuB {
    display: block;
    width: 100%;
    text-align: center;
    color: red; }
  ._3ifROftlLo12_qx7WxmKQk ._3hrEiJPdgPtgIcO1WC9SGP {
    color: #068b3e; }
  ._3ifROftlLo12_qx7WxmKQk ._1JBGGizs637GlV9l64hWp5 {
    margin-top: 20px; }
    ._3ifROftlLo12_qx7WxmKQk ._1JBGGizs637GlV9l64hWp5 ._2pRzVvWObHqvVMGQ11RUAm button {
      width: 100%; }
    ._3ifROftlLo12_qx7WxmKQk ._1JBGGizs637GlV9l64hWp5 ._2pRzVvWObHqvVMGQ11RUAm i {
      margin-right: 10px;
      margin-bottom: 1px; }
